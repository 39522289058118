import React, { useRef } from "react";
import { motion } from "framer-motion";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";
import { ArrowIcon } from "./utility-components";

const WatchModel = () => {
  const meshRef = useRef();
  const stl = useLoader(STLLoader, "/watch.stl");

  const shaderMaterial = new THREE.ShaderMaterial({
    uniforms: {
      time: { value: 0 },
    },
    vertexShader: `
      varying vec3 vNormal;
      void main() {
        vNormal = normal;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform float time;
      varying vec3 vNormal;
      void main() {
        vec3 color = vec3(0.5) + 0.5 * cos(time + vNormal.xyx + vec3(0, 2, 4));
        gl_FragColor = vec4(color, 1.0);
      }
    `,
  });

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    meshRef.current.rotation.z = time * 0.5;
    meshRef.current.rotation.x = 3.14/2;
    shaderMaterial.uniforms.time.value = time;
  });

  return (
    <mesh ref={meshRef} scale={[0.055, 0.055, 0.055]} material={shaderMaterial}>
      <primitive object={stl} />
    </mesh>
  );
};

const ProjectCard = ({ project, onClick }) => {
  return (
    <div
      className="bg-transparent overflow-hidden cursor-pointer w-full mx-0 px-0 z-10"
      onClick={() => onClick(project)}
    >
      <div className="relative h-56 w-full mx-auto">
        <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <WatchModel />
        </Canvas>
      </div>
      <div className="p-6">
        <h3 className="text-xl mx-auto text-center font-bold mb-2 text-white">
          {project.title}
        </h3>
        <p className="text-sm text-center text-gray-300 mb-4 line-clamp-2">
          {project.description}
        </p>
        <div className="flex justify-center flex-wrap gap-2 mb-4">
          {project.technologies.slice(0, 2).map((tech) => (
            <span
              key={tech}
              className="bg-blue-900 text-blue-200 px-2 py-1 rounded-full text-xs font-medium"
            >
              {tech}
            </span>
          ))}
          {project.technologies.length > 2 && (
            <span className="bg-blue-900 text-gray-200 px-2 py-1 rounded-full text-xs font-medium">
              +{project.technologies.length - 2}
            </span>
          )}
        </div>
        <motion.div
          className="flex justify-center"
          whileHover={{ x: 5 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <span className="text-blue-500 dark:text-blue-400 inline-flex items-center">
            Open Project <ArrowIcon />
          </span>
        </motion.div>
      </div>
    </div>
  );
};

export default ProjectCard;