const projects = [
  {
    id: 1,
    title: "Smartwatch",
    description: "A full-stack e-commerce solution with React and Node.js",
    fullDescription:
      "This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.This project is a comprehensive e-commerce platform built using React for the frontend and Node.js for the backend. It features user authentication, product catalog, shopping cart functionality, and payment integration.",
    technologies: ["React", "Node.js", "Express", "MongoDB", "Stripe"],
    image: "gradient0.png",
    hasExternalLink: true,
    externalLink: "https://example.com/smartwatch",
  },
  {
    id: 2,
    title: "Restaurant Menu",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient1.png",
    hasExternalLink: false,
  },
  {
    id: 3,
    title: "Pepe",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: true,
    externalLink: "https://example.com/pepe",
  },
  {
    id: 4,
    title: "Task Management App",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: false,
  },
  {
    id: 5,
    title: "Helo",
    description: "A Trello-like task management application",
    fullDescription:
      "This task management app allows users to create boards, lists, and cards to organize their projects. It includes features like drag-and-drop functionality, user collaboration, and real-time updates.",
    technologies: ["React", "Redux", "Firebase", "Material-UI"],
    image: "gradient2.png",
    hasExternalLink: false,
  },
  {
    id: 6,
    title: "Amazing Project",
    image: "gradient2.png",
    fullDescription: "This is a full description of the amazing project...",
    technologies: ["React", "Node.js", "MongoDB"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
  },
];

export default projects;