import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import projects from "./projects-data.js";
import ProjectCard from "./project-card";
import ProjectModal from "./project-modal";
import vaporwaveSun from "../../vaporwaveSun.png";
import "../../assets/fonts/neon/neonfont.css"

const ProjectsSection = () => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <div className="">
      <div className="max-w-7xl mx-auto">
        <img
          src={vaporwaveSun}
          style={{
            zIndex: -20,
          }}
          className="w-[10rem] h-[10rem] m-auto my-[20rem] mb-[5rem]"
          alt="Vaporwave Sun"
        />
        <motion.h2
          className="text-4xl md:text-5xl font-light text-center mb-[10rem] text-white dark:text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            textShadow: `0 0 5px blue, 0 0 10px blue, 0 0 15px blue, 0 0 20px blue`,
            color: "#ffffff",
            fontFamily: 'Neon'
          }}
        >
          {t("projectsTitle")}
        </motion.h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-0 px-0">
          {projects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onClick={() => {
                console.log('ProjectCard clicked:', project.id);
                setSelectedProject(project);
              }}
            />
          ))}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {selectedProject && (
          <ProjectModal
            key="modal"
            project={selectedProject}
            onClose={() => {
              console.log('Closing modal');
              setSelectedProject(null);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectsSection;