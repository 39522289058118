import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { X, ExternalLink, Github } from "lucide-react";

const ProjectModal = ({ project, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20 backdrop-blur-xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }} // Increased from 0.3 to 0.5
    >
      <div className="absolute inset-0" onClick={onClose} />
      <motion.div
        className="w-full max-w-4xl h-full sm:h-[90vh] overflow-hidden flex flex-col relative z-10 bg-transparent"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{
          type: "spring",
          damping: 30,
          stiffness: 300,
          exit: { duration: 0.4 }, // Added exit duration of 0.4 seconds
        }}
      >
        <div className="relative w-[20vh] lg:w-56 mx-auto mt-8">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-full object-cover object-center rounded-lg"
          />
        </div>
        <motion.button
          className="absolute top-4 right-4 bg-black/50 rounded-full p-2 backdrop-blur-sm hover:bg-gray-800/50 transition-colors"
          onClick={onClose}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <X className="h-6 w-6 text-white" />
        </motion.button>
        <div className="flex-grow flex flex-col min-h-0 relative p-6 sm:p-8">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-white">
            {project.title}
          </h2>
          <div className="flex-grow overflow-y-auto pr-4 mb-4 relative">
            <p className="text-gray-300 text-base sm:text-lg leading-relaxed mb-6">
              {project.fullDescription}
            </p>
          </div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3 text-white">
              Technologies Used:
            </h3>
            <div className="flex flex-wrap gap-2">
              {project.technologies.map((tech) => (
                <span
                  key={tech}
                  className="bg-gray-800 text-gray-200 px-3 py-1 rounded-full text-sm font-medium"
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>
          <div className="flex flex-row lg:flex-row gap-4 mt-auto pt-4 border-t border-gray-700">
            {project.externalLink && (
              <motion.a
                href={project.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex w-full items-center justify-center bg-white text-black px-6 py-1 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <ExternalLink className="h-5 w-5 mr-3" />
                Visit Project
              </motion.a>
            )}
            {project.githubLink && (
              <motion.a
                href={project.githubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex w-full items-center justify-center bg-gray-800 text-white px-6 py-1 rounded-lg font-semibold hover:bg-gray-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Github className="h-5 w-5 mr-3" />
                Open GitHub
              </motion.a>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ProjectModal;
