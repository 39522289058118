import React, { useMemo, useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import WavyGradientBackground from "./elements/NoiseBackground";
import avatar from "./avatar.png";
import "./assets/fonts/actonia/actonia.css";

// import RotatingTorus from "./elements/RotatingTorus.js";
// import WavyGradientBackground from './elements/NoiseBackground.js'

const IconButton = React.memo(({ href, ariaLabel, children }) => (
  <a
    href={href}
    aria-label={ariaLabel}
    className="
      p-3
      rounded-full
      bg-transparent
      outline
      outline-2
      outline-gray-300
      hover:outline-white
      transition-all
      duration-300
      hover:shadow-[0_0_10px_3px_rgba(255,255,255,1)]
    "
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
));

const glowColors = ["#2563eb", "#9333ea", "#0d9488", "#dc2626"];

let globalColorIndex = 0;

const CustomBold = React.memo(({ children }) => {
  const colorIndex = useRef(globalColorIndex);

  // Increment the global color index for the next component instance
  globalColorIndex = (globalColorIndex + 1) % glowColors.length;

  const color = glowColors[colorIndex.current];

  return (
    <strong
      className="font-medium z-50"
      style={{
        textShadow: `0 0 5px ${color}, 0 0 10px ${color}, 0 0 15px ${color}, 0 0 20px ${color}`,
        color: "#ffffff",
      }}
    >
      {children}
    </strong>
  );
});

const CustomReadable = React.memo(({ children }) => {
  const color = "#000000";
  return <p className="font-medium mix-blend-color-dodge">{children}</p>;
});

const PresentationSection = ({ color, colorLow, colorMid, colorHigh }) => {
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const socialIcons = useMemo(
    () => [
      {
        href: "https://github.com/johndoe",
        ariaLabel: "GitHub",
        icon: (
          <svg
            className="w-5 h-5"
            fill="#ffffff"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 0C5.37 0 0 5.37 0 12c0 5.3 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 21.795 24 17.295 24 12c0-6.63-5.37-12-12-12" />
          </svg>
        ),
      },
      {
        href: "https://linkedin.com/in/johndoe",
        ariaLabel: "LinkedIn",
        icon: (
          <svg
            className="w-5 h-5"
            fill="#ffffff"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
          </svg>
        ),
      },
      {
        href: "mailto:john.doe@example.com",
        ariaLabel: "Email",
        icon: (
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="#ffffff"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        ),
      },
    ],
    []
  );

  const titleGlowSpread = "20px";

  return (
    <section className="min-h-[100svh] flex items-center justify-center bg-transparent">
      <div className="w-full max-w-xl lg:max-w-4xl px-5 z-10">
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 5 }}
            className="mb-8"
          >
            <img src={avatar} className="w-[15rem] lg:w-[20rem]"></img>
          </motion.div>
          <div className="flex-1 text-center max-w-md lg:max-w-xl lg:text-left">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-5xl font-bold mb-2 text-white"
              style={{
                textShadow:
                  "1px 1px " +
                  titleGlowSpread +
                  " red, 1px -1px " +
                  titleGlowSpread +
                  " blue, -1px 1px " +
                  titleGlowSpread +
                  " purple, -1px -1px " +
                  titleGlowSpread +
                  " teal",
                color: "#ffffff",
                fontFamily: "Neon",
                fontSize: "2.5rem",
                fontWeight: "lighter",
              }}
            >
              Angelo Germinario
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="text-lg lg:text-2xl mb-6 text-gray-300 pointer-events-none"
            >
              {t("titles")}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="text-base max-w-md lg:text-lg mb-8 text-gray-200"
              style={{
              }}
            >
              <ReactMarkdown
                components={{ strong: CustomBold, p: CustomReadable }}
              >
                {t("introduction")}
              </ReactMarkdown>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="flex justify-center lg:justify-start space-x-4"
            >
              {socialIcons.map((icon, index) => (
                <IconButton
                  key={index}
                  href={icon.href}
                  ariaLabel={icon.ariaLabel}
                >
                  {icon.icon}
                </IconButton>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
      <WavyGradientBackground
        fogColor={color}
        colorLow={colorLow}
        colorMid={colorMid}
        colorHigh={colorHigh}
      ></WavyGradientBackground>
    </section>
  );
};

export default React.memo(PresentationSection);
